<template>
  <div class="container-fluid" id="login">
    <Load :isLoading="isLoading" />
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">

                    <!-- form card login -->
                    <div class="card rounded shadow shadow-sm">
                        <div class="card-header">
                            <h3 class="mb-0">Login</h3>
                        </div>
                        <div class="card-body">
                            <form class="form" @submit.prevent="logar">
                                <div class="form-group">
                                    <label for="uname1">Usuário</label>
                                    <input v-model="login.usuario" type="text" class="form-control form-control-lg rounded-0">
                                </div>
                                <div class="form-group">
                                    <label>Senha</label>
                                    <input v-model="login.senha" type="password" class="form-control form-control-lg rounded-0" autocomplete="new-password">
                                </div>
                                <button type="submit" class="btn btn-success btn-lg float-right" id="btnLogin">Login</button>
                            </form>
                        </div>
                        <!--/card-block-->
                    </div>
                    <!-- /form card login -->

                </div>


            </div>
            <!--/row-->

        </div>
        <!--/col-->
    </div>
    <!--/row-->
</div>
<!--/container-->
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import loginRepository from './../../services/api/loginRepository'
import perfilAcessoRepository from './../../services/api/perfilAcessoRepository'
export default {
    name: 'LoginAdmin',
    validations: {
      login: {
          usuario: {
            required
          },
          senha: {
            required
          }
        }
    },
    data () {
      return {
        isLoading: false,
        login: {
          usuario: null,
          senha: null
        }
      }
    },
    methods: {
      async logar () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Preencha os campos!',
          })
        }
        else {
          let login = {
            usuario: this.login.usuario,
            password: this.login.senha
          }
          this.isLoading = true
          loginRepository.login(login).then(response => {
						if (response.data['success']) {
							this.$store.commit('SET_LOGIN', response.data['data'].username)
              this.$store.commit('SET_TIME_LOGIN')
              this.$store.commit('SET_API_TOKEN', response.data['data'].access_token)
              perfilAcessoRepository.buscarPermissoesUsuarioLogado().then(responsePermissao => {
                if(responsePermissao.data['success']) {
                  // console.log(responsePermissao.data['data'])
                  this.$store.commit('SET_PERMISSION', responsePermissao.data['data'])
                  this.$router.replace('/admin/treinamentos')
                } else {
                  this.$swal({
                    icon: 'error',
                    text: responsePermissao.data['message']
                  })
                  loginRepository.logout().then(() => this.$store.commit('SET_LOGOUT'))
                }
                this.isLoading = false
              })
              
						} else {
              this.isLoading = false
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o login!'
							})
						}
					}).catch( error => {
            console.log(error)
            this.isLoading = false
						this.$swal({
							icon: 'error',
							text: 'Não foi possível realizar o login!'
						})
					})
        }
      }
    }
}
</script>

<style scoped>
#login::before {
  background-image: url('~@/assets/images/logo-transparente.png');
  overflow-x: hidden;
  /*background-image: url("https://gestorvida.agenciaportes.com.br/logo-transparente.png");*/
  background-size: 100px;
  content: "";
  height: 100vh;
  width: 100vw;
  position: fixed;
  opacity: 0.2;
}
</style>