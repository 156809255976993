import Repository from './repository'

const resource = '/v1/configuracoes/perfil'

export default {
  buscaPerfilAcesso (idPerfil) {
    return Repository.get(`${resource}/${idPerfil}`)
  },
  listarPerfisAcesso () {
    return Repository.get(`${resource}`)
  },
  salvarPerfilAcesso (perfil) {
    return Repository.post(`${resource}`, perfil)
  },
  atualizarPerfilAcesso (idPerfil, perfil) {
    return Repository.put(`${resource}/${idPerfil}`, perfil)
  },
  deletarPerfilAcesso (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  buscaPerfilAcessoPermissoes (idPerfil) {
    return Repository.get(`${resource}/${idPerfil}/permissoes`)
  },
  buscarPermissoesUsuarioLogado () {
    return Repository.get(`${resource}/permissoes`)
  },
  salvarPerfilAcessoPermissoes (permissao) {
    return Repository.post(`${resource}/permissao`, permissao)
  }
}
